import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import AddToQueueTwoToneIcon from '@mui/icons-material/AddToQueueTwoTone';

const menuItems = [
  {
    heading: '我的專案',
    items: [
      {
        name: '專案列表',
        icon: AccountTreeTwoToneIcon,
        link: '/projects',
      },
      {
        name: '新增專案',
        icon: AddToQueueTwoToneIcon,
        link: '/add-project',
      }
    ]
  },
  {
    heading: '個人資料',
    items: [
      {
        name: '我的資料',
        icon: AssignmentIndTwoToneIcon,
        link: '/account',
      },
      {
        name: '修改密碼',
        icon: VpnKeyTwoToneIcon,
        link: '/change-password',
      }
    ]
  }
];

export default menuItems;
