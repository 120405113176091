import { useRef, useEffect } from 'react';

const useRefMounted = () => {
  const isRef = useRef(true);

  useEffect(
    () => () => {
      isRef.current = process.env.NODE_ENV === 'development';
    },
    []
  );

  return isRef;
};

export default useRefMounted;
