import { useState, forwardRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  TextField,
  Typography,
  Container,
  Alert,
  Slide,
  Dialog,
  Collapse,
  Button,
  Avatar,
  IconButton,
  styled,
  CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import useRefMounted from 'src/hooks/useRefMounted';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import useAuth from 'src/hooks/useAuth';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function EmailConfirmation() {
  const { t } = useTranslation();
  // const { verifyEmailWithCode } = useAuth();
  const isMountedRef = useRefMounted();
  const [status, setStatus] = useState('success');

  const code = new URLSearchParams(window.location.search).get('code');
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  // useEffect(() => {
  //   if (isMountedRef.current) {
  //     if (!code) {
  //       enqueueSnackbar('請求無效', {
  //         variant: 'error'
  //       });
  //       navigate('/account/login');
  //     } else {
  //       const verifyEmail = async () => {
  //         try {
  //           // await verifyEmailWithCode(code);
  //           setStatus('success');
  //         } catch (err) {
  //           setStatus('error');
  //         }
  //       }
  //       verifyEmail();
  //     }
  //   }
  // }, [third])


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>驗證Email信箱</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="sm">
          <Logo />
          <Card
            sx={{
              mt: 3,
              p: 4
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('驗證Email信箱')}
              </Typography>
            </Box>
            {
              status === 'success' && (
                <Box>
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3
                    }}
                  >
                    {t(
                      '完成！'
                    )}
                  </Typography>
                  <Typography>
                    {t(
                      '驗證成功，請返回登入頁面'
                    )}
                  </Typography>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleCloseDialog}
                    href="/account/login"
                    style={{
                      marginTop: '1rem'
                    }}
                  >
                    {t('返回登入')}
                  </Button>
                </Box>
              )
            }
            {
              status === 'error' && (
                <Box>
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3
                    }}
                  >
                    {t(
                      '驗證失敗'
                    )}
                  </Typography>
                  <Typography>
                    {t(
                      '驗證失敗，請再次確認您的Email信箱'
                    )}
                  </Typography>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleCloseDialog}
                    style={{
                      marginTop: '1rem'
                    }}
                    href="/account/login"
                  >
                    {t('返回登入')}
                  </Button>
                </Box>
              )
            }
            {
              status === 'verifying' && (
                <Box>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3
                    }}
                  >
                    {t(
                      '正在驗證您的Email信箱，請稍後...'
                    )}
                  </Typography>
                  <CircularProgress />
                </Box>
              )
            }
          </Card>
          <Box mt={3} textAlign="right">
            <Typography
              component="span"
              variant="subtitle2"
              color="text.primary"
              fontWeight="bold"
            >
              {t('想要再次嘗試登入？')}
            </Typography>{' '}
            <Link component={RouterLink} to="/account/login">
              <b>前往登入</b>
            </Link>
          </Box>
        </Container>
      </MainContent>

      <DialogWrapper
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            px: 4,
            pb: 4,
            pt: 10
          }}
        >
          <AvatarSuccess>
            <CheckTwoToneIcon />
          </AvatarSuccess>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 10
            }}
            variant="h3"
          >
            {t('已發送重設密碼連結')}
          </Typography>

          <Typography
            align="center"
            color="text.secondary"
            sx={{
              pb: 4,
              px: 10
            }}
            variant="subtitle2"
          >
            {t(
              '如果您在我們的記錄中輸入了正確的電子郵件地址，我們將向您發送一封包含密碼重置的電子郵件。'
            )}
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleCloseDialog}
            href="/account/login"
          >
            {t('返回登入')}
          </Button>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default EmailConfirmation;
