import axios from 'axios';
import { appConfig } from 'src/config';
// import AxiosMockAdapter from 'axios-mock-adapter';

const axiosInt = axios.create(
  {
    baseURL: appConfig.API_BASE_URL,
  }
);

export default axiosInt;