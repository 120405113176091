import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Pages

const Projects = Loader(lazy(() => import('src/content/project/Projects')));

const projectRoutes = [
    {
        path: '',
        element: <Projects />
    },
    {
        path: 'add-project',
        element: <Navigate to="/" replace />
    },
];

export default projectRoutes;
