import { createContext, useEffect, useReducer } from 'react';
import axios from 'src/utils/axios';
import { verify } from 'src/utils/jwt';
import PropTypes from 'prop-types';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const getAxiosErrorMessage = (errorMessage) => {
  const statusCode = errorMessage?.response?.status;
  const axiosMessage = errorMessage?.message;
  const strapiMessage = errorMessage?.response?.data?.error?.message;

  switch (statusCode) {
    case 401:
      return '請先登入';
    case 403:
      return '權限不足';
    case 404:
      return '找不到資源';
    case 500:
      return '伺服器發生錯誤';
    default:
      break;
  }

  if (strapiMessage) {
    switch (strapiMessage) {
      case 'Invalid identifier or password':
        return '帳號或密碼錯誤';
      case 'Forbidden':
        return '權限不足';
      case 'Request failed with status code 403':
        return '權限不足';
      case 'Incorrect code provided':
        return '傳回參數錯誤';
      default:
        return strapiMessage;
    }
  }

  switch (axiosMessage) {
    case 'Network Error':
      return '網路發生錯誤，或是無法連線到伺服器，請稍後再試';
    case 'Request failed with status code 401':
      return '請先登入';
    case 'Request failed with status code 403':
      return '權限不足';
    case 'Request failed with status code 404':
      return '找不到資源';
    case 'Request failed with status code 500':
      return '伺服器錯誤';
    default:
      return errorMessage;
  }
}

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyEmailWithCode: () => Promise.resolve(),
  sendResetPasswordEmail: () => Promise.resolve(),
  sendResetPasswordWithCode: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {

      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            logout();
          }
          console.log(error);
          error.message = getAxiosErrorMessage(error);
          return Promise.reject(error);
        }
      );

      console.log('init auth');

      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && verify(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/users/me');
          const user = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (identifier, password) => {
    const response = await axios.post('/auth/local', {
      identifier,
      password
    });

    const { user, jwt } = response.data;

    setSession(jwt);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/auth/local/register', {
      email: email,
      username: name,
      password
    });
    const { jwt, user } = response.data;

    window.localStorage.setItem('accessToken', jwt);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const sendResetPasswordEmail = async (email) => {
    await axios.post('/auth/forgot-password', {
      email
    });
  };

  const sendResetPasswordWithCode = async (code, password, passwordConfirmation) => {
    await axios.post('/auth/reset-password', {
      code,
      password,
      passwordConfirmation
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        sendResetPasswordEmail,
        sendResetPasswordWithCode
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
